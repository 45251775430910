
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useToast } from "vue-toastification";
import { defineComponent } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Iso6391languages from "@/core/constants/Iso-639-1-to-language";
import "vue-select/dist/vue-select.css";

interface User {
    id: string,
    firstname: string,
    lastname: string,
    isEmailVerified: boolean,
    isDoctor: boolean,
    superAdmin: boolean,
    email: string,
    accountType: string,
    presentation: {
        image: string,
        description: string,
        experience: string,
        spokenLanguages: string[],
    } | undefined,
    presentationDraft: {
        image: string,
        description: string,
        experience: string,
        spokenLanguages: string[],
        approved: boolean,
    } | undefined,
}

export default defineComponent({
    name: "account",
    components: {
        ErrorMessage,
        Field,
    },
    data() {
        return {
            newPassword: "",
            newPasswordConfirmation: "",
            iso6391languages: Iso6391languages,
            image: null as Blob | null,
            isUploading: false,
            apiUrl: process.env.VUE_APP_API_URL,
            user: null as User | null,
        };
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    mounted() {
        setCurrentPageBreadcrumbs("Account", []);
        const userId = JwtService.getUser().id;
        // Query up-to-date user information via the API (not the session token)
        ApiService.get('/users', userId, null).then(({ data: user }) => {
            this.user = {
                id: user.id,
                isDoctor: user.isDoctor,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                presentation: user.presentation,
                presentationDraft: user.presentationDraft,
            } as User;
        });
    },
    methods: {
        handleUserImageUpload(file) {
            this.isUploading = true;
            const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
            if (!allowedTypes.includes(file.type)) {
                alert("Only jpeg and png images are allowed");
                this.isUploading = false;
                return;
            }
            if (file.size > 2 * 1000000) { // Max. 2Mo
                alert("File too large, max. 2Mo");
                this.isUploading = false;
                return;
            }
            this.image = file;
            this.isUploading = false;
        },

        saveNewPassword() {
            if (!this.user) return;
            if (this.newPassword != this.newPasswordConfirmation) {
                this.toast.error("Passwords do not match");
                return;
            }

            ApiService.update(
                "/users",
                this.user.id,
                { password: this.newPassword },
                {
                    responseToast: {
                        text: "Password updated successfully.",
                    },
                }
            )
                .then(() => {
                    this.newPassword = "";
                    this.newPasswordConfirmation = "";
                });
        },

        saveProfileDetails() {
            if (!this.user) return;

            const config = {
                responseToast: {
                    text: "Information updated successfully.",
                },
            };
            ApiService.update("/users", this.user.id, this.user, config)
                .then(({ data }) => {
                    console.log(data);
                    // Once classic fields have been updated, handle image
                    if (this.user && this.image) {
                        const formData = new FormData();
                        formData.append("image", this.image as Blob);
                        ApiService
                            .update("/users", this.user.id, formData)
                            .then(({ data }) => {
                                this.image = null;
                                if (data?.presentationDraft && this.user) {
                                    this.user.presentationDraft = data.presentationDraft;
                                }
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
});
